<template>
  <div id="map" class="h-full rounded-xl"></div>
</template>

<script setup>
import 'leaflet/dist/leaflet.css';
import {onMounted} from "vue";

const props = defineProps({
  markers: [{name: '', coordinates: []}],
  zoom: {
    type: Number,
    default: 13
  }
})

let map;

onMounted(async () => {
  const L = (await import('leaflet')).default;
  L.Icon.Default.imagePath='/images/leaflet/';
  map = L.map('map')

  L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(map);

  if (props.markers) {
    props.markers.forEach(marker => {
      L.marker(marker.coordinates).addTo(map)
          .bindPopup(marker.name)
    })

    const bounds = new L.LatLngBounds(props.markers.map(agency => agency.coordinates))
    map.fitBounds(bounds, {maxZoom: props.zoom});
  }
})

onUnmounted(() => {
  if (map && map.remove) {
    map.off();
    map.remove();
  }
})
</script>
